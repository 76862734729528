import React from 'react'
import { Button } from 'components/CustomButtons'
import useStyles from './styles'

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <h1>404</h1>
      </div>

      <div>
        <h3 className="h2">Look like you're lost</h3>

        <p>the page you are looking for not avaible!</p>

        <Button variant="contained" color="primary" href="/">
          Go to Home
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
